<template>
  <div
    id="pricing"
    class="flex flex-col items-center justify-center w-full gap-4 relative"
  >
    <!-- Glow -->
    <div class="absolute pointer-events-none left-0 -top-80">
      <img
        src="/img/pages/welcome/billing-glow.png"
        alt="glow"
      >
    </div>
    <div
      class="max-w-screen-xl px-5 xl:px-0 mx-auto relative lg:before:bg-hr-hor lg:before:w-[1px] lg:before:h-full lg:before:absolute lg:before:-left-6 lg:before:top-0 lg:after:bg-hr-hor lg:after:w-[1px] lg:after:h-full lg:after:absolute lg:after:-right-6 lg:after:top-0"
    >
      <div class="text-center mx-auto">
        <h2
          class="text-dark text-2xl md:text-4xl leading-none font-semibold text-center pt-4 md:pt-0 mx-auto md:max-w-full"
        >
          Take your forms to the next level with Pro. <br>
          <span class="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">Simple pricing.</span>
        </h2>
      </div>
      <div class="my-8 flex items-center justify-center">
        <MonthlyYearlySelector
          v-model="isYearly"
          class="mt-5"
        />
      </div>
      <div
        class="flex flex-wrap justify-center gap-4"
      >
        <!-- Basic -->
        <div
          v-if="!authenticated"
          class="p-6 space-y-4 bg-white shadow-md rounded-2xl border border-opacity-5 border-dark max-w-xs"
        >
          <span class="text-dark-400 text-xl font-semibold">Basic</span>
          <h4 class="text-dark font-semibold text-5xl">
            $0<span
              class="text-gray text-sm font-medium"
            >/month</span>
          </h4>
          <p class="text-dark-200 text-sm">
            The basic toolset to create an unlimited number of forms with
            Notion.
          </p>
          <hr class="border-gray-700">
          <ul class="space-y-4 md:h-[345px]">
            <li
              v-for="feature in basicPlanFeatures"
              :key="feature"
              class="text-gray-700 text-sm flex items-center gap-1"
            >
              <Icon
                name="heroicons:check-circle"
                class="w-6 h-6"
              />
              <span>{{ feature }}</span>
            </li>
          </ul>
          <v-button
            color="white"
            class="border border-gray-300 h-10 inline-flex px-4 items-center rounded-lg text-dark-300 text-sm font-medium w-full justify-center"
            font-weight="semibold"
            :to="{ name: ('register') }"
          >
            Start now
          </v-button>
        </div>

        <!-- Pro -->
        <div class="p-6 space-y-4 bg-gradient-blue shadow-md rounded-2xl border border-opacity-5 border-dark max-w-xs">
          <span class="text-white text-xl font-semibold">Pro</span>
          <h4 class="text-white font-semibold text-5xl">
            <template v-if="isYearly">
              {{ formattedPrices.default.yearly }}
            </template>
            <template v-else>
              {{ formattedPrices.default.monthly }}
            </template>
            <span class="text-gray-50 text-sm font-medium">/month</span>
          </h4>
          <p class="text-gray-50 text-sm">
            Everything you need to create beautiful and professional forms.
          </p>
          <hr class="border-gray-300">
          <ul class="space-y-4 md:h-[345px]">
            <li
              v-for="feature in proPlanFeatures"
              :key="feature"
              class="text-gray-50 text-sm flex items-center gap-1"
            >
              <Icon
                name="heroicons:check-circle"
                class="w-6 h-6"
              />
              <span>{{ feature }}</span>
            </li>
          </ul>
          <v-button
            v-if="!authenticated || !user.is_subscribed"
            color="white"
            class="relative border border-white border-opacity-20 h-10 inline-flex px-4 items-center rounded-lg !text-blue-500 text-sm font-semibold w-full justify-center"
            font-weight="semibold"
            @click.prevent="onSelectPlan('default')"
          >
            Start 3-day trial
          </v-button>
          <v-button
            v-else-if="authenticated && !user.has_enterprise_subscription"
            disabled
            color="transparent"
            class="border border-white w-full font-medium text-sm"
          >
            Your current plan
          </v-button>
        </div>

        <!-- Team -->
        <div class="p-6 space-y-4 bg-white shadow-md rounded-2xl border border-solid border-blue-400 relative max-w-xs">
          <div
            class="absolute right-4 top-7 rounded-full bg-green-100 font-semibold text-green-500 px-3 py-1 uppercase text-xs"
          >
            Summer sale -50%
          </div>
          <span class="text-dark-400 text-xl font-semibold">Team</span>
          <div
            v-if="isYearly"
            class="flex flex-col relative"
          >
            <p class="w-full line-through text-gray-500 text-sm -mt-4">
              {{ formattedPrices.enterprise.monthly }}/month
            </p>
            <h4
              class="text-dark font-semibold text-5xl"
            >
              <span class="text-blue-500">{{ formattedPrices.enterprise.yearly_discounted }}</span>
              <span class="text-gray text-sm font-medium">/month</span>
            </h4>
          </div>
          <h4
            v-else
            class="text-dark font-semibold text-5xl"
          >
            {{ formattedPrices.enterprise.monthly }}
            <span class="text-gray text-sm font-medium">/month</span>
          </h4>
          <p class="text-dark-200 text-sm">
            For companies that need to collaborate on their forms.
          </p>
          <hr class="border-gray-700">
          <ul class="space-y-4 md:h-[345px]">
            <li
              v-for="feature in teamPlanFeatures"
              :key="feature"
              class="text-gray-700 text-sm flex items-center gap-1"
            >
              <Icon
                name="heroicons:check-circle"
                class="w-6 h-6"
              />
              <span>{{ feature }}</span>
            </li>
          </ul>
          <v-button
            v-if="!authenticated || !user.has_enterprise_subscription"
            color="blue"
            class="border h-10 inline-flex px-4 items-center rounded-lg text-dark-300 text-sm font-medium w-full justify-center"
            font-weight="semibold"
            @click.prevent="onSelectPlan('enterprise')"
          >
            {{ authenticated && user.is_subscribed ? 'Upgrade Now' : 'Start 3-day trial' }}
          </v-button>
          <v-button
            v-else-if="authenticated && user.has_enterprise_subscription"
            disabled
            color="transparent"
            class="border border-blue-500 !text-blue-500 w-full font-medium text-sm bg-transparent"
          >
            Your current plan
          </v-button>
        </div>
      </div>
    </div>

    <CustomPlan v-if="$route.name === 'pricing'" />

    <div
      v-if="$route.name !== 'pricing'"
      class="flex justify-center my-6 w-full"
    >
      <VButton
        class="group"
        color="white"
        shadow="none"
        :to="{ name: 'pricing' }"
      >
        Read more about our different plans
        <Icon
          name="heroicons:chevron-right"
          class="h-5 w-5 inline -mr-1 -mt-[2px] group-hover:translate-x-1 transition-transform duration-200 ease-in-out"
        />
      </VButton>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import MonthlyYearlySelector from '../pricing/MonthlyYearlySelector.vue'
import CustomPlan from '../pricing/CustomPlan.vue'
import { getCurrency, getFormattedPrices } from '~/lib/localized-pricing'

export default {
  components: { MonthlyYearlySelector, CustomPlan },
  props: {},
  setup () {
    const subscriptionModalStore = useSubscriptionModalStore()
    const authStore = useAuthStore()
    return {
      subscriptionModalStore,
      authenticated: computed(() => authStore.check),
      user: computed(() => authStore.user),
    }
  },
  data: () => ({
    isYearly: true,
    currency: getCurrency(),
    basicPlanFeatures: [
      '1 user',
      '1 Notion workspace',
      'Unlimited forms',
      'Unlimited submissions',
      'Embed form anywhere',
      '1 database view'
    ],
    proPlanFeatures: [
      '1 user',
      '1 Notion workspace',
      'Remove NoteForms branding',
      'Full form customization',
      'Enhanced form notifications',
      'Integrations and form logic',
      '10 MB file uploads',
      'Unlimited database views'
    ],
    teamPlanFeatures: [
      'Unlimited users',
      'Multiple Notion workspaces',
      'All features from Pro plan',
      'Editable submissions',
      'Custom domains',
      '50 MB file uploads',
      'Priority support'
    ]
  }),

  computed: {
    formattedPrices () {
      return getFormattedPrices(this.currency)
    }
  },

  methods: {
    onSelectPlan (planName) {
      if (this.authenticated) {
        this.subscriptionModalStore.openModal(planName, this.isYearly)
        return
      }
      const router = useRouter()
      router.push({ name: 'register' })
    }
  }
}
</script>

